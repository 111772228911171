import type { Review } from '~/types/api'

export default function useReview() {
  return useAsyncData('reviews', () => useNuxtApp().$api.content.getReviews(), {
    default: () => [] as Review[],
    transform: (response) => response.data,
    getCachedData: (key, nuxt): Review[] =>
      nuxt.payload.data[key] || nuxt.static.data[key],
    dedupe: 'defer',
  })
}
