<script lang="ts" setup>
import type { Review } from '~/types/api'

const { data: reviews } = await useReview()

useSchemaOrg([
  defineAggregateRating({
    ratingValue:
      reviews.value.reduce((acc, review) => acc + review.rating, 0) /
      reviews.value.length,
    reviewCount: reviews.value.length,
    bestRating: 5,
  }),

  ...reviews.value.map((review) =>
    defineReview({
      author: {
        name: review.name,
      },
      reviewRating: review.rating,
      reviewBody: review.review,
    }),
  ),
])
</script>

<template>
  <UCarousel
    arrows
    :items="reviews"
    :ui="{
      container: 'gap-6',
      item: 'basis-full md:basis-[calc(50%-0.75rem)] justify-center snap-start',
    }"
    :next-button="{ class: '-right-3' }"
    :prev-button="{ class: '-left-3' }"
  >
    <template #default="{ item: review }: { item: Review }">
      <UCard class="w-full text-center" :ui="{ body: { padding: 'sm:py-12' } }">
        <div class="mx-auto w-full">
          <h3 class="font-serif text-2xl">{{ review.name }}</h3>
          <UIRating :rating="review.rating" />
          <p
            class="line-clamp-3 text-sm leading-loose text-gray-600 md:text-base md:leading-loose"
          >
            {{ review.review }}
          </p>
        </div>
      </UCard>
    </template>
  </UCarousel>
</template>
